import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/nftx-developers.github.io/nftx-developers.github.io/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Carbon’s Gatsby Theme uses version 4 of the Gatsby library. This update combines
the control and scalability of server-side rendering with the performance of
static-site generation. The major version change comes with the potential for
breaking changes, this guide aims to cover the most likely issues a site built
with the Carbon Gatsby theme might encounter. To have the smoothest possible
experience, we recommend `}<a parentName="p" {...{
          "href": "/guides/v2-migration"
        }}>{`upgrading to v2`}</a>{` of Carbon’s
Gatsby Theme first if you haven’t already done so.`}</p>
    </PageDescription>
    <h2>{`Breaking changes`}</h2>
    <ol>
      <li parentName="ol">{`Bumped minimum version of Gatsby to `}<inlineCode parentName="li">{`v4`}</inlineCode></li>
      <li parentName="ol">{`Removed `}<inlineCode parentName="li">{`carbon-components-react`}</inlineCode>{` and `}<inlineCode parentName="li">{`carbon-components`}</inlineCode>{` in favor of
installing `}<inlineCode parentName="li">{`@carbon/react`}</inlineCode>{` effectively migrating to Carbon v11.`}</li>
    </ol>
    <h2>{`Gatsby migration guide`}</h2>
    <p>{`In this section we’ll cover the most likely issues site’s built with the Carbon
gatsby theme might encounter when migrating. If you encounter any issues, or
your site leverages more advanced gatsby configuration, check to see if it’s
covered in the
`}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v3-to-v4/"
      }}>{`official Gatsby v4 migration guide`}</a>{`.
To facilitate an easy upgrade path, we recommend
`}<a parentName="p" {...{
        "href": "/guides/v2-migration"
      }}>{`upgrading to v2`}</a>{` of Carbon’s Gatsby Theme first if you
haven’t already done so.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Note: We’ll use yarn for the shell commands here. Replace them with the `}<inlineCode parentName="p">{`npm`}</inlineCode>{`
equivalent if your project uses `}<inlineCode parentName="p">{`npm`}</inlineCode>{` as its package manager.`}</p>
    </InlineNotification>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Update Gatsby to v4`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`yarn add gatsby@latest-v4
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update gatsby plugins (including the theme)`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`yarn upgrade-interactive --latest
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<inlineCode parentName="p">{`yarn dev:clean`}</inlineCode>{` to delete and rebuild your projects development bundle`}</p>
      </li>
    </ol>
    <h2>{`Troubleshooting`}</h2>
    <ul>
      <li parentName="ul">{`Try deleting your node_modules, running `}<inlineCode parentName="li">{`yarn cache clean`}</inlineCode>{` and `}<inlineCode parentName="li">{`yarn install`}</inlineCode>{`
(or `}<inlineCode parentName="li">{`npm cache clean --force`}</inlineCode>{` and npm install)`}</li>
      <li parentName="ul">{`Feel free to reach out in the #gatsby-theme-carbon slack channel or open an
issue on GitHub`}</li>
    </ul>
    <h2>{`Carbon v11 migration guide`}</h2>
    <p>{`As part of this release, the theme has been updated to use Carbon v11. In this
section we’ll cover the areas that will need to be updated in relation to this
dependency upgrade.`}</p>
    <ol>
      <li parentName="ol">{`Update your color, motion, and type tokens where appropriate. Use
`}<a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/blob/main/docs/migration/v11.md"
        }}>{`the migration guide from Github`}</a>{`
for a fantastic token reference guide.`}</li>
    </ol>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Pro tip: When attempting to build your local dev environment, it will usually
throw an error because of an old v10 token. Do a search for the v10 token in
question throughout the entire project. Once found, copy it to your clipboard,
then switch over to the migration guide on Github. Hit `}<inlineCode parentName="p">{`CMD+F`}</inlineCode>{` to search the
page to find/replace with the corresponding v11 token.`}</p>
    </InlineNotification>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Update icon usage`}</li>
    </ol>
    <p>{`As part of v11, the usage pattern for icons has changed. Icons are no longer
imported by size, instead size is configured via the `}<inlineCode parentName="p">{`size`}</inlineCode>{` prop. Full details
can be found in the
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/main/docs/migration/v11.md#changes-to-size"
      }}>{`Carbon v11 migration guide`}</a>{`.`}</p>
    {/* prettier-ignore-start */}
    <pre><code parentName="pre" {...{
        "className": "language-md",
        "metastring": "path=Before",
        "path": "Before"
      }}>{`import { Add32, Add24, Add20, Add16 } from '@carbon/icons-react';

function MyComponent() {
  return (
    <>
      <Add32 />
      <Add24 />
      <Add20 />
      <Add16 />
    </>
  );
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-md",
        "metastring": "path=After",
        "path": "After"
      }}>{`import { Add } from '@carbon/icons-react';

function MyComponent() {
  return (
    <>
      <Add size={32} />
      <Add size={24} />
      <Add size={20} />
      <Add />
    </>
  );
}
`}</code></pre>
    {/* prettier-ignore-end */}
    <p>{`A very easy way to make this change is to use the automated migration tool
available from the
`}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/main/packages/upgrade/README.md#usage"
      }}>{`Carbon Upgrade CLI`}</a>{`.
You can run a test of this automated migration against your codebase with the
following command. When ready to write the changes, additionally pass the `}<inlineCode parentName="p">{`-w`}</inlineCode>{`
flag`}</p>
    <pre><code parentName="pre" {...{}}>{`npx @carbon/upgrade migrate icons-react-size-prop
`}</code></pre>
    <InlineNotification mdxType="InlineNotification">
      <p>{`This automated migration will not rewrite imports in `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` files. Search all
your `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` files for `}<inlineCode parentName="p">{`@carbon/icons-react`}</inlineCode>{` and manually update their import.
Additionally update their usage to include the appropriate `}<inlineCode parentName="p">{`size`}</inlineCode>{` prop.`}</p>
    </InlineNotification>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Remove any prefix imports. Replace the following in your classnames:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-md",
        "metastring": "path=Before",
        "path": "Before"
      }}>{`<div className={\`\${prefix}--fancy-class\`}>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-md",
        "metastring": "path=After",
        "path": "After"
      }}>{`<div className='cds--fancy-class'>
`}</code></pre>
    <p>{`The tips above should get you most, if not all of the way there, but your
mileage may vary based on how much you have customized your site. If you have
still have issues check out the v11 migration guides below.`}</p>
    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="v11 Migration Guide on Github" href="https://github.com/carbon-design-system/carbon/blob/main/docs/migration/v11.md" actionIcon="launch" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACW0lEQVQ4y3WUP2uUQRDG93JnE4tw784zZ9IERU1hIRYprBTxC0hAUoiJVjYGrrCSkMp8CSHB4lqRFGdpYWGTJoVWqWJnRMgpuXj/lNl79r3hSF4YWGZnfvvM7OwbFBJoFUis2LqhuKyQNYW8V8iRQvq0I/rWGhCLCcypZE4JU4Vt2nqdif8g8VyzPcaspxxFyNASVtTnbL2bQYp4RlUDhQxptu4D8cyBd4tirhQWEIusrEUFI6dudI7CUYa5teUmVoY1J8pkRyEPFdKmghOFfKed0NdmzI5T2jSWwRYU0nEbKyY/xrrtLSvkmgKzY7O1LEtRzyWuOCEdY5lzC5JAp9zYSPIl1twEBAXKte2xsg3mnJKxZc5917uuQu4wcYZW8aPl/IGxXdfLfXMeu3FoUd3MwhUNF33zDU0xU5dp+cchjYHEPh2bvtyLvqmyNwlMw2+OoQNuE1gtJ3/qa0z6WCVw2wGHgTece7CXFb64txqiFAkw9o1BMRbh1tJNr3DP3UHHHIeuB3YpN6jgkkJKpQ0gmfm4Z+slhfx1+YdlUxXyh3P4TSGLPD1MgOLLDZzJA+bmp9iyjfsEfVLII0Xs8c2+VcgTVamppj+QAU3ZU4W8MwGEDCBxQMaDfNqHPNQKuZumfxzwMY+RG5O265mBer7/uaR5hfzkKdcVUvCtzrqBzi+nybieg/3gE07A3PjbCvltTVbIS4U8dq+m4uJe8f12Cfvl4qq55By8qJAvVGD2hv6aU/ja/Ug+K+RqhqWS2aMSKrHIf5EDhTzLwe7Q5wr5qpDVWBTBwwz4H843DYgmF8gjAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Github icon",
              "title": "Github icon",
              "src": "/static/9768c17dd6866f8eeda39a4825cc09f2/59afc/github-icon.png",
              "srcSet": ["/static/9768c17dd6866f8eeda39a4825cc09f2/59afc/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="v11 Migration Guide" href="https://carbondesignsystem.com/migrating/guide/develop" actionIcon="launch" mdxType="ResourceCard">
  </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      